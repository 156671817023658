<template>
  <div>
    <payment-with-wallet
      :loading="{ isDeletingCard, isPaying }"
      :cards="registeredCreditCards"
      :selected-card-id="selectedCardId"
      @change:card="onChangeSelectedCard"
      @click:delete="onDeleteCard"
      @click:pay="onPay"
      @click:add-card="$router.push({ name: 'credit-card-registration' })"
      @click:back="$refs.hotelPayment.openModal()"
    />
    <hotel-payment-container ref="hotelPayment" />
  </div>
</template>

<script setup>
import { ref } from "vue"
import { useRouter } from "vue-router/composables"
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers"

import { useStoreAction } from "@/composables"
import i18n from "@/i18n"

import PaymentWithWallet from "./components/PaymentWithWallet.vue"
import HotelPaymentContainer from "./containers/HotelPaymentContainer.vue"

const { registeredCreditCards } = useNamespacedState("warranty/payment", [
  "registeredCreditCards",
])

const { sendNotification } = useNamespacedActions("notifications", [
  "sendNotification",
])

const {
  paymentWithRegistration,
  isLoading: isPaying,
  hasError: paymentHasError,
} = useStoreAction("warranty/payment", "paymentWithRegistration")

const {
  deleteCard,
  isLoading: isDeletingCard,
  hasError: deleteCardHasError,
} = useStoreAction("warranty/payment", "deleteCard")

const { getCards } = useStoreAction("warranty/payment", "getCards")

getCards()

const router = useRouter()

let selectedCardId = ref("")

const onChangeSelectedCard = ({ selected, cardId }) => {
  if (selected) {
    selectedCardId.value = cardId
  } else {
    selectedCardId.value = ""
  }
}

const onDeleteCard = async (cardId) => {
  await deleteCard(cardId)

  if (cardId === selectedCardId.value) {
    selectedCardId.value = ""
  }

  if (!deleteCardHasError.value) {
    sendNotification({
      message: "Cartão excluido com sucesso!",
      duration: 5000,
      confirmationText: "Ok",
      color: "var(--lightgreen-500)",
    })

    await getCards()
  }
}

const onPay = async () => {
  await paymentWithRegistration(selectedCardId.value)

  if (paymentHasError.value) {
    sendNotification({
      message: i18n.tc("error.generic_error"),
      duration: 5000,
      confirmationText: "Ok",
      color: "var(--orange-500)",
    })
  } else {
    router.push({ name: "processing-payment" })
  }
}
</script>
