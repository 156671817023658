<template>
  <z-card width="auto" shadow>
    <template #content>
      <div class="card-wrapper">
        <div class="selection">
          <z-icon v-if="isSelected" @click="$emit('change', !isSelected)"
            >$check_box_rounded</z-icon
          >
          <z-icon v-else @click="$emit('change', !isSelected)"
            >$check_box_outline_blank_rounded</z-icon
          >
        </div>
        <div class="content">
          <div class="card-brand">
            <v-img height="50" width="50" contain :src="cardFlag" />
          </div>
          <div class="card-info">
            <p class="body-bold">
              {{ brand }} -
              {{ type.charAt(0).toUpperCase() + type.slice(1) }}
            </p>
            <p class="body-text">
              {{ `•••• •••• •••• ${lastFourDigits}` }}
            </p>
          </div>
        </div>
        <div class="delete">
          <z-tooltip>
            <template #activator="{ on, attrs }">
              <z-icon
                v-bind="attrs"
                @click="isDeleteModalOpened = true"
                v-on="on"
                >$delete</z-icon
              >
            </template>

            {{ $t("warrantyRegister.delete_payment_method") }}
          </z-tooltip>
        </div>
      </div>

      <delete-card-modal
        :is-opened="isDeleteModalOpened"
        :is-loading="isLoading"
        @back="isDeleteModalOpened = false"
        @delete="$emit('click:delete')"
      ></delete-card-modal>
    </template>
  </z-card>
</template>

<script setup>
import { computed, ref } from "vue"

import DeleteCardModal from "./modals/DeleteCardModal.vue"

const props = defineProps({
  brand: {
    type: String,
    default: "",
  },

  type: {
    type: String,
    default: "",
  },

  lastFourDigits: {
    type: [String, Number],
    default: "",
  },

  isSelected: {
    type: Boolean,
    default: false,
  },

  isLoading: {
    type: Boolean,
    default: false,
  },
})

const cardFlag = computed(() =>
  require(`@/assets/images/credit-card-flags/${props.brand.toLowerCase()}.png`)
)

let isDeleteModalOpened = ref(false)
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: flex;
  align-items: center;
  gap: 50px;

  .content {
    display: flex;
    gap: 15px;
    align-items: center;

    .card-info {
      text-align: left;
    }

    .card-brand {
      mix-blend-mode: difference;
    }
  }

  .delete {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}
</style>
